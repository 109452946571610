@import "src/assets/scss/magic";
header {
    padding: rem(18) rem(34);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: 500ms transform;
    &.hide {
        transform: translate3d(0, -100%, 0);
    }
    @include mobile {
        padding: rem(14) rem(20);
        width: 100vw;
    }
}
.header-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    > li {
        display: flex;
        align-items: center;
        &:last-child {
            justify-content: flex-end;
        }
        > * {
            + * {
                @include desktop {
                    margin: 0 0 0 rem(8);
                }
            }
        }
    }
}
.hc-toggle-struct {
    width: rem(66);
    height: rem(66);
    position: relative;
    cursor: pointer;
    font: 300 rem(28) / rem(32) $f;
    text-transform: uppercase;
    @include mobile {
        width: rem(30);
        height: rem(30);
    }
    > li {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 600ms opacity;
        display: flex;
        align-items: center;
        justify-content: center;
        background: no-repeat 50% 50% / contain;
    }
}
.hc-a-branch {
    @media (hover: hover) {
        &:hover > li:first-child, &:not(:hover) > li:nth-child(2) {
            opacity: 0;
        }
    }
    &.t-ed {
        > li {
            &:first-child {
                background-image: url("./media/ed.png");
                @include retina {
                    background-image: url("./media/ed@2x.png");
                }
            }
        }
    }
    &.t-lab {
        > li {
            &:first-child {
                background-image: url("./media/lab.png");
                @include retina {
                    background-image: url("./media/lab@2x.png");
                }
            }
        }
    }
}
.menu-toggle {
    @include mobile {
        --icon-size: #{rem(16)};
    }
    &.active > li:first-child, &:not(.active) > li:last-child {
        opacity: 0;
    }
    > li {
        &:first-child {
            background-image: url("./media/ws.png");
            @include retina {
                background-image: url("./media/ws@2x.png");
            }
        }
    }
}
.logo-holder {
    display: flex;
    color: black;
    text-transform: uppercase;
    align-items: baseline;
    font: 300 rem(42) / rem(66) $f;
    @include mobile {
        font: 300 rem(14) / rem(20) $f;
        position: relative;
        z-index: 100;
    }
    span {
        &:first-child {
            font-weight: 800;
            font-family: $fs;
        }
    }
}
.inline-logo {
    font-weight: 300;
    font-family: $f;
    text-transform: uppercase;
    span {
        vertical-align: baseline;
        &:first-child {
            font-weight: 800;
            font-family: $fs;
        }
    }
}
