@function set-color($color, $color-dark: #191822, $color-light: #ffffff) {
    @if $color == #EF5350 or $color == #3d76ff {
        @return $color-light;
    }
    @if (lightness($color) > 50) {
        @return $color-dark;
    } @else {
        @return $color-light;
    }
}
@function c($color, $opacity: 1) {
    @if $opacity == 1 {
        @return var(--#{$color});
    } @else {
        @return rgba(var(--#{$color}-rgb), $opacity);
    }
}
@function ch($color) {
    @return var(--#{$color}-hover);
}
@function sc($color, $opacity: 1) {
    @if $opacity == 1 {
        @return var(--#{$color}-sc);
    } @else {
        @return rgba(var(--#{$color}-sc-rgb), $opacity);
    }
}
@function per($size, $guide: 1448) {
    $per: $size / $guide * 100;
    @return #{$per + '%'};
}
@function rem($size) {
    $remSize: $size / 10;
    @if $size == 1 {
        @return #{$size}px;
    }
    @return #{$remSize}rem;
}
@function -rem($size) {
    $remSize: $size / 10;
    @if $size == 1 {
        @return -#{$size}px;
    }
    @return -#{$remSize}rem;
}
@mixin over($n1, $n2) {
    --#{$n1}: var(--#{$n2});
    --#{$n1}-hover: var(--#{$n2}-hover);
    --#{$n1}-sc: var(--#{$n2}-sc);
    --#{$n1}-rgb: var(--#{$n2}-rgb);
    --#{$n1}-sc-rgb: var(--#{$n2}-sc-rgb);
}
@mixin media-hover {
    @media (hover: hover) {
        @content;
    }
}
@mixin hover($other: null) {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
    @if $other != null {
        &#{$other} {
            @content;
        }
    }
}
@mixin hover-not-active($selector: null) {
    @media (hover: hover) {
        @if $selector != null {
            #{$selector}:hover:not(:active) & {
                @content;
            }
        } @else {
            &:hover:not(:active) {
                @content;
            }
        }
    }
}
@mixin hover-active {
    @media (hover: hover) {
        &:hover:not(:active) {
            @content;
        }
    }
    &.active {
        @content;
    }
}
@mixin placeholder {
    $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
    @each $prefix in $prefixList {
        &#{$prefix}-placeholder {
            @content;
            opacity: 1;
        }
    }
}
@mixin retina {
    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        @content;
    }
}
@function set-lightness($color, $amount) {
    @if (lightness($color) > 50) {
        @return darken($color, $amount);
    } @else {
        @return lighten($color, $amount);
    }
}
@mixin mobile {
    @media screen and (max-width: 576px) {
        @content;
    }
}
@mixin desktop {
    @media screen and (min-width: 577px) {
        @content;
    }
}
