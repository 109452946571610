@import "src/assets/scss/magic";
html .intro-container {
    @include desktop {
        min-height: 100vh;
        margin: 0 auto rem(342);
        padding-top: rem(130);
        padding-bottom: rem(130);
        display: flex;
        > * {
            margin: auto;
        }
    }
}
.intro-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include mobile {
        margin: rem(100) 0 rem(134);
    }
    figure {
        position: relative;
        width: per(1000);
        margin: 0 0 rem(36);
        img {
            max-width: 100%;
        }
        @include mobile {
            width: 100%;
            --icon-size: #{rem(46)};
            margin: 0 0 rem(30);
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(white, 0.6);
            @include hover {
                color: rgba(white, 0.8);
            }
        }
    }
    h1 {
        font: 300 rem(54) / rem(72) $f;
        text-align: left;
        @include mobile {
            font-size: rem(24);
            line-height: rem(40);
            text-align: center;
        }
        strong {
            font-weight: 500;
            font-family: $fs;
            vertical-align: baseline;
        }
    }
}
