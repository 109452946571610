@import "src/assets/scss/magic";
.services-holder {
    margin: rem(200) 0 rem(486);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr rem(540);
    @include mobile {
        grid-template-columns: 100%;
    }
    > li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > h3 {
            font: 600 rem(58) / rem(68) $f;
            cursor: pointer;
            transition: 400ms color;
            @include hover {
                color: black;
            }
            + h3 {
                margin: rem(54) 0 0;
            }
        }
    }
}
.service-sub-holder {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    color: black;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(16);
    font: 300 rem(38) / rem(50) $f;
    transition: 500ms opacity, 500ms visibility;
    &:not(.active) {
        visibility: hidden;
        opacity: 0;
    }
}
.services-mobile-holder {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(60);
    margin: rem(100) 0;
    text-align: center;
    > li {
        display: grid;
        grid-row-gap: rem(30);
        grid-template-columns: 100%;
    }
    h3 {
        font: 600 rem(24) / rem(28) $f;
    }
}
.smb-content {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(20);
    font: 300 rem(16) / rem(20) $f;
}
