@import "src/assets/scss/magic";
.navigation-holder {
    --socials-holder-external-gap: #{rem(16)};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 rem(30);
    transition: 500ms opacity, 500ms visibility;
    overflow-y: auto;
    overflow-x: hidden;
    @include mobile {
        padding: 0 rem(24);
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
    }
    &:not(.active) {
        opacity: 0;
        visibility: hidden;
    }
    > li {
        display: flex;
        align-items: center;
        padding: rem(36) 0;
        @include mobile {
            &:last-child {
                order: -1;
            }
        }
    }
}
.navigation-footnote {
    align-self: flex-end;
    color: black;
    display: flex;
    align-items: flex-end;
    @include mobile {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        --socials-holder-external-gap: #{rem(41)};
        --icon-size: #{rem(18)};
    }
    p {
        margin: 0 rem(20);
        text-transform: uppercase;
        font: 600 rem(16) / 1 $fs;
        @include mobile {
            margin: rem(16) 0 0;
            font: 600 rem(11) / 1 $fs;
        }
    }
}
.navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > li {
        transition: 400ms color;
        color: #484848;
        font: 100 rem(170) / rem(200) $f;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        @include hover {
            color: black;
        }
        @include mobile {
            font: 300 rem(68) / rem(86) $f;
        }
        + li {
            margin: rem(38) 0 0;
            @include mobile {
                margin: rem(44) 0 0;
            }
        }
        small {
            width: rem(86);
            font: 600 rem(40) / 1 $fs;
            margin: rem(36) 0 0;
            @include mobile {
                font-size: rem(14);
                width: rem(26);
                margin: rem(18) 0 0;
            }
        }
    }
}
