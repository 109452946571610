@import "src/assets/scss/magic";
.socials-holder {
    display: flex;
    align-items: center;
    > a {
        display: flex;
        @include hover {
            color: #484848;
        }
        + a {
            margin: 0 0 0 var(--socials-holder-external-gap, #{rem(42)});
        }
    }
}
