@import '../../assets/scss/magic';
.icon {
    --size: #{rem(24)};
    width: var(--size);
    height: var(--size);
    font-size: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 400ms transform;
    cursor: pointer;
    &.icon-spinner {
        &:before {
            display: block;
            animation: rotate 300ms infinite linear;
            @keyframes rotate {
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
