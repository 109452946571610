@import 'magic';
.absolutely-splash, .fixed-splash {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.absolutely-splash {
    position: absolute;
    @at-root {
        img#{&} {
            object-fit: cover;
        }
    }
}
.fixed-splash {
    position: fixed;
}
.relative {
    position: relative;
}
.divider {
    background: sc(b);
    height: rem(2);
    width: 100%;
}
.guide {
    margin: 0 auto;
    width: 100%;
    max-width: rem(1448 + (78 * 2));
    padding: 0 rem(78);
    display: flex;
    flex-direction: column;
    @include mobile {
        padding: 0 rem(24);
    }
    &.overflow-hidden {
        overflow: hidden;
    }
}
h2 {
    font: 100 rem(152) / 1 $f;
    position: relative;
    @include mobile {
        font-size: rem(52);
        padding: 0 rem(18);
    }
    small {
        display: block;
        position: absolute;
        right: 100%;
        top: rem(20);
        width: rem(78);
        font: 600 rem(36) / 1 $fs;
        @include mobile {
            font-size: rem(12);
            width: rem(18);
            left: 0;
            right: auto;
            top: rem(8);
        }
    }
}
.link-effect {
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    transform: translate3d(0, 0, 0);
    &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(1);
        background: currentColor;
        transform-origin: right;
        transform: scaleX(1);
        transition: transform .6s cubic-bezier(.165,.84,.44,1) .3s;
    }
    &:after {
        transform-origin: left;
    }
    &:not(:hover):after, &:hover:before {
        transform: scaleX(0);
        transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }
    &.hover-active {
        &:not(:hover):not(.active) {
            &:after {
                transition-delay: 0ms;
            }
            &:before {
                transform: scaleX(0);
            }
        }
    }
}
.loader-holder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font: rem(40) / 2 $f;
    letter-spacing: rem(1);
    color: black;
    @include mobile {
        font-size: rem(20);
    }
}
