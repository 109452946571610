@import 'magic';
* {
    margin: 0;
    border: 0;
    padding: 0;
    outline: 0;
    border-radius: 0;
    background: none;
    vertical-align: top;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-tap-highlight-color: transparent;
    &, &:before, &:after {
        box-sizing: border-box;
    }
}
a {
    color: inherit;
    text-decoration: none;
    transition: 400ms color, 400ms background;
}
button,
input,
optgroup,
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    font: inherit;
    appearance: none;
    display: block;
    color: inherit;
    caret-color: c(brand);
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
    &::-ms-clear {
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        background: none;
    }
    @include placeholder {
        opacity: 1;
        font-family: inherit;
    }
}
input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}
html {
    font: 10px / 1.6 $f;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    max-width: 100vw;
    background: white;
    color: #484848;
    @media screen and (max-width: 370px) {
        font-size: 9px;
    }
    @include desktop {
        @media screen and (max-width: 1760px) {
            font-size: 9px;
        }
        @media screen and (max-width: 1660px) {
            font-size: 8px;
        }
        @media screen and (max-width: 1440px) {
            font-size: 7.5px;
        }
        @media screen and (max-width: 1204px) {
            font-size: 7px;
        }
        @media screen and (max-width: 1112px) {
            font-size: 6.5px;
        }
        @media screen and (max-width: 1042px) {
            font-size: 6px;
        }
        @media screen and (max-width: 962px) {
            font-size: 5px;
        }
        @media screen and (max-width: 802px) {
            font-size: 4px;
        }
        @media screen and (max-width: 641px) {
            font-size: 3px;
        }
    }
}
body {
    font-size: rem(18);
    min-height: 100%;
    display: flex;
}
#root {
    width: 100%;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    line-height: normal;
}
li {
    display: block;
}
.ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}
.user-select-none {
    user-select: none;
}
.pointer-events-none {
    pointer-events: none;
}
.cursor-pointer {
    cursor: pointer;
}
.user-select {
    user-select: text;
    > * {
        user-select: text;
    }
}
