@import "src/assets/scss/magic";
.static-content-holder {
    width: 100%;
    display: grid;
    grid-auto-columns: 100%;
    grid-row-gap: rem(120);
    margin: rem(200) 0;
    @include mobile {
        grid-row-gap: rem(60);
        margin: rem(100) 0;
    }
    h1 {
        text-align: center;
        font: 800 rem(60) / 1.4 $fs;
        color: black;
        text-transform: uppercase;
        @include mobile {
            font-size: rem(28);
        }
    }
}
.static-content {
    font: 300 rem(24) / 1.8 $f;
    color: #151414;
    @include mobile {
        font-size: rem(16);
    }
    h1, h2, h3, h4, h5, h6 {
        font: 800 rem(42) / 1 $fs;
        @include mobile {
            text-align: center;
            font-size: rem(26);
        }
    }
    em, i {
        font-style: italic;
        font-weight: 700;
        color: black;
    }
    p {
        @include desktop {
            padding-bottom: rem(20);
        }
    }
    > * {
        + * {
            margin-top: rem(48);
            @include mobile {
                margin-top: rem(16);
            }
        }
    }
}
