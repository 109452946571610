@import "src/assets/scss/magic";
.contact-holder {
    width: 100%;
    display: grid;
    grid-template-columns: rem(528) 1fr;
    margin: rem(364) 0 0;
    color: black;
    @include desktop {
        border: solid black;
        border-width: rem(1) 0;
    }
    @include mobile {
        grid-template-columns: 100%;
        margin: rem(114) 0 0;
        padding: 0 rem(24);
        --socials-holder-external-gap: #{rem(30)};
        --icon-size: #{rem(26)};
    }
    > li {
        &:nth-child(2) {
            @include mobile {
                order: -1;
            }
        }
        + li {
            display: flex;
            align-items: center;
            justify-content: center;
            @include desktop {
                border-left: rem(1) solid black;
            }
        }
    }
}
.contact-details {
    width: 100%;
    @include mobile {
        margin: rem(14) 0 0;
    }
    > li {
        display: flex;
        align-items: center;
        justify-content: center;
        @include desktop {
            height: rem(112);
        }
        + li {
            @include desktop {
                border-top: rem(1) solid black;
            }
            @include mobile {
                margin: rem(20) 0 0;
            }
        }
    }
}
.contact-newsletter {
    font: 300 rem(32) / rem(38) $f;
    @include mobile {
        font: 300 rem(24) / rem(28) $f;
        display: flex;
        align-items: center;
        --icon-size: #{rem(14)};
        > * {
            + * {
                margin: 0 0 0 rem(12);
            }
        }
    }
}
.contact-email {
    font: 300 rem(88) / rem(94) $f;
    @media screen and (max-width: 1860px) {
        font: 300 rem(76) / rem(84) $f;
    }
    @include mobile {
        font: 300 rem(24) / rem(28) $f;
    }
}
