@import "src/assets/scss/magic";
.manifesto-holder {
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(336);
    margin: rem(276) 0 rem(558);
    @include mobile {
        grid-row-gap: rem(140);
        margin: rem(140) 0;
    }
}
