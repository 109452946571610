@import "src/assets/scss/magic";
.manifesto-item-holder {
    width: 100%;
}
.manifesto-item {
    display: grid;
    grid-template-columns: per(811) 1fr;
    grid-column-gap: rem(38);
    align-items: flex-end;
    transition: 400ms color;
    position: relative;
    @include mobile {
        grid-template-columns: 100%;
    }
    @include hover {
        color: black;
    }
    figure {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
        figcaption {
            position: absolute;
            top: 100%;
            margin: rem(12) 0 0;
            font: 500 rem(16) / rem(20) $fs;
            text-transform: uppercase;
            display: block;
            @include mobile {
                font: 500 rem(12) / rem(16) $fs;
            }
        }
    }
    > li {
        &:last-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include mobile {
                align-items: flex-end;
            }
        }
    }
    &.reverse {
        @include desktop {
            grid-template-columns: 1fr  per(811);
            > li {
                &:first-child {
                    order: 1;
                }
                &:last-child {
                    order: 0;
                    align-items: flex-end;
                }
            }
            figcaption {
                left: auto;
                right: 0;
            }
        }
        h4 {
            @include mobile {
                text-align: right;
            }
        }
    }
    h4 {
        font: 300 rem(86) / rem(88) $f;
        text-transform: uppercase;
        @include mobile {
            font: 300 rem(26) / rem(34) $f;
            margin: 0 0 rem(10);
            span {
                display: inline-block;
                vertical-align: baseline;
                + span {
                    margin: 0 0 0 rem(6);
                }
                &:first-of-type {
                    font-weight: 800;
                    font-family: $fs;
                }
            }
        }
        @include desktop {
            &:first-line {
                font-weight: 800;
                font-family: $fs;
            }
            br {
                &:nth-last-of-type(3) {
                    display: none;
                }
            }
        }
    }
}
.manifesto-item-cta-holder {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 500ms opacity, 500ms max-height;
    display: flex;
    &.active {
        max-height: rem(90);
        opacity: 1;
    }
}
.manifesto-item-cta {
    margin: rem(26) 0 0;
    cursor: pointer;
    text-transform: uppercase;
    font: 600 rem(30) / rem(36) $fs;
    @include mobile {
        margin: rem(12) 0 0;
        font: 600 rem(12) / rem(16) $fs;
    }
}
.manifesto-projects {
    margin: rem(260) 0 0;
    width: 100%;
    @include mobile {
        margin: rem(66) 0 0;
    }
    > ul {
        border-top: rem(1) solid black;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(var(--child-count), 1fr);
        &:last-of-type {
            border-bottom: rem(1) solid black;
        }
        &.cols-2 {
            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                > li {
                    &:empty {
                        display: none;
                        &:first-child {
                            + li {
                                border: 0;
                            }
                        }
                    }
                }
                &.c-3 {
                    > li {
                        &:empty + li:empty + li {
                            border: 0;
                            grid-column: span 2;
                        }
                    }
                }
            }
        }
        &.c-2 {
            &.empty-not-found {
                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                    .wide {
                        grid-column: span 1;
                    }
                }
            }
            @include mobile {
                > li {
                    &.wide {
                        &:empty {
                            display: none;
                            + li:last-child {
                                border: 0;
                                grid-column: span 3;
                            }
                        }
                    }
                }
            }
        }
        > li {
            min-height: rem(534);
            padding: rem(38);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 600ms background;
            &:not(.clickable) {
                pointer-events: none;
            }
            @include mobile {
                padding: rem(28) rem(24);
                min-height: rem(160);
            }
            &.clickable {
                cursor: pointer;
                @include hover {
                    background: #F5EAF7;
                }
            }
            &.wide {
                grid-column: span 2;
            }
            + li {
                border-left: rem(1) solid black;
            }
            > .animated-holder {
                width: auto;
            }
        }
    }
}
.mp-image-holder {
    position: relative;
    @include desktop {
        height: rem(320);
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        pointer-events: none;
        @include mobile {
            max-height: rem(180);
        }
    }
}
.mp-caption {
    position: absolute;
    top: 100%;
    left: 0;
    margin: rem(14) 0 0;
    font: 500 rem(20) / 1 $fs;
    text-transform: uppercase;
    color: #484848;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include mobile {
        margin: rem(6) 0 0;
        font: 600 rem(12) / 1 $fs;
    }
}
.mp-children-holder {
    font: rem(32) / rem(44) $f;
    padding: 0 per(40, 720);
    @include mobile {
        padding: rem(4) 0;
        font: rem(12) / rem(18) $f;
    }
    strong {
        font-weight: 500;
        font-family: $fs;
        vertical-align: baseline;
    }
}
.manifesto-projects-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: rem(32) rem(38);
    @include mobile {
        padding: rem(12) rem(24);
    }
    button {
        display: flex;
        align-items: center;
        height: rem(34);
        font: 600 rem(28) / rem(32) $fs;
        text-transform: uppercase;
        cursor: pointer;
        transition: 400ms color;
        color: #484848;
        @include mobile {
            height: rem(18);
            font: 600 rem(12) / rem(18) $fs;
            --icon-size: #{rem(8)};
        }
        @include hover {
            color: black;
        }
        > * {
            + * {
                margin: 0 0 0 rem(16);
                @include mobile {
                    margin: 0 0 0 rem(8);
                }
            }
        }
    }
}
.manifesto-projects-clip-overflow {
    width: 100%;
    overflow: hidden;
    height: 0;
    transition: 1s height, 600ms opacity;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    &:not(.active) {
        opacity: 0;
    }
    &.active {
        transition: 300ms height, 600ms opacity;
    }
    > li {
        padding: 0 0 rem(5);
        display: flex;
        width: 100%;
    }
}
.cell-children-holder {
    &:not(.fancybox__content) {
        display: none !important;
    }
    &.fancybox__content {
        font: 300 rem(36) / 1.8 $f;
        color: #151414;
        em, i {
            font-style: italic;
            font-weight: 700;
            color: black;
        }
        .cell-children {
            > p {
                text-indent: rem(60);
                @include mobile {
                    text-indent: rem(30);
                }
            }
            > * {
                + * {
                    margin-top: rem(48);
                    @include mobile {
                        margin-top: rem(24);
                    }
                }
            }
        }
        @include desktop {
            padding-left: 0;
            padding-right: 0;
        }
        @include mobile {
            font-size: rem(18);
        }
    }
}
