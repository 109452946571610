@import "magic";
@import "node_modules/@fancyapps/ui/src/Carousel/Carousel.scss";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/_variables";
$fancybox-container-color: #484848;
$fancybox-backdrop-bg: white;
$fancybox-content-color: var(--fancybox-content-color, #374151);
$fancybox-content-bg: var(--fancybox-content-bg, #fff);
$fancybox-video-width: 1920px;
$fancybox-video-height: 1080px;
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/_mixins";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/base";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/spinner";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/animations";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/scrollbar";
@import "node_modules/@fancyapps/ui/src/Fancybox/scss/cursor";
@import "node_modules/@fancyapps/ui/src/Fancybox/plugins/Image/Image.scss";
@import "node_modules/@fancyapps/ui/src/Fancybox/plugins/Html/Html.scss";
@import "node_modules/@fancyapps/ui/src/Fancybox/plugins/Thumbs/Thumbs.scss";
.fancybox__thumbs {
    display: none;
}
.fancybox__slide {
    padding: rem(100) rem(150);
    @include mobile {
        padding: rem(40) 0;
    }
}
.carousel__button {
    width: rem(36);
    height: rem(36);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0 !important;
    font: rem(36) / 1 'icomoon';
    color: #484848;
    transition: 300ms color, 500ms transform !important;
    @include mobile {
        width: rem(30);
        height: rem(30);
        font-size: rem(16);
    }
    @include hover {
        color: black;
    }
    &.is-close {
        top: rem(30);
        right: rem(50);
        @include mobile {
            top: rem(14);
            right: rem(20);
        }
        &:before {
            content: "\e901";
        }
    }
    .fancybox__nav & {
        @include mobile {
            color: rgba(white, 0.7);
        }
        @include hover {
            transform: translateY(-50%) scaleY(1.4);
        }
        &.is-next {
            right: rem(50);
            @include mobile {
                right: rem(20);
            }
            &:before {
                content: "\e90a";
            }
        }
        &.is-prev {
            left: rem(50);
            @include mobile {
                left: rem(20);
            }
            &:before {
                content: "\e90b";
            }
        }
    }
    svg {
        display: none !important;
    }
}
.fancybox__caption {
    display: none !important;
}
.fancybox-custom-caption {
    color: #000000;
    text-transform: uppercase;
    @include desktop {
        position: absolute;
        display: grid;
        grid-column-gap: rem(6);
        grid-template-columns: 1fr auto 1fr;
        top: 100%;
        align-items: center;
        left: 0;
        margin: rem(14) 0 0;
        width: 100%;
        font: 500 rem(16) / 1 $fs;
        > li {
            &:last-child {
                text-align: right;
            }
            &:nth-child(2) {
                font-weight: 300;
                font-style: italic;
            }
            &:not(:nth-child(2)) {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    @include mobile {
        > li {
            padding: rem(16) rem(24);
            position: absolute;
            left: 0;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: center;
            font: 500 rem(12) / 1 $fs;
            &:first-child {
                display: none;
            }
            &:nth-child(2) {
                top: 100%;
                font-weight: 300;
                font-style: italic;
            }
            &:last-child {
                bottom: 100%;
            }
        }
    }
}
.fancybox-project-title {
    position: fixed;
    top: rem(14);
    z-index: 100;
    left: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    font: 800 rem(14) / rem(30) $fs;
    color: black;
    text-transform: uppercase;
    padding: 0 rem(54);
    text-align: center;
    @include desktop {
        display: none;
    }
}
.fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content, .fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
    background: none;
}
