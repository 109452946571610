@import "src/assets/scss/magic";
footer {
    padding: rem(80) rem(30) rem(45);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 600 rem(16) / rem(20) $fs;
    color: black;
    @include mobile {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: rem(50) rem(24) rem(20);
        text-align: center;
        font: 600 rem(12) / rem(16) $fs;
    }
    p {
        text-transform: uppercase;
        @include mobile {
            margin: rem(8) 0 0;
            font-size: rem(11);
        }
    }
    a {
        font-weight: 300;
    }
}
